import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import AdminDashboard from './views/admin/AdminDashboard.vue';

import AdminClients from './views/admin/clients/AdminClients.vue';
import AdminClientsView from './views/admin/clients/AdminClientsView.vue';

import AdminRequests from './views/admin/requests/AdminRequests.vue';
import AdminRequestsView from './views/admin/requests/AdminRequestsView.vue';

import AdminUsers from './views/admin/users/AdminUsers.vue';

import DocsProjects from './views/docs/projects/DocsProjects.vue';
import DocsProjectsGet from './views/docs/projects/DocsProjectsGet.vue';
import DocsProjectsSubmit from './views/docs/projects/DocsProjectsSubmit.vue';
import DocsProjectsCancel from './views/docs/projects/DocsProjectsCancel.vue';

import DocsQuery from './views/docs/query/DocsQuery.vue';
import DocsQueryDatasets from './views/docs/query/DocsQueryDatasets.vue';
import DocsQuerySubbasins from './views/docs/query/DocsQuerySubbasins.vue';

import DocsBuilder from './views/docs/builder/DocsBuilder.vue';
import DocsBuilderCreateProject from './views/docs/builder/DocsBuilderCreateProject.vue';
import DocsBuilderCreateScenario from './views/docs/builder/DocsBuilderCreateScenario.vue';
import DocsBuilderZipProject from './views/docs/builder/DocsBuilderZipProject.vue';

Vue.use(Router)

export default new Router({
	routes: [
		{
			path: '/', name: 'Home', component: Home,
			children: [
				{
					path: '/docs/projects', name: 'DocsProjects', component: DocsProjects, meta: { title: 'Projects' },
					children: [
						{
							path: 'submit', name: 'DocsProjectsSubmit', component: DocsProjectsSubmit, meta: { title: 'Submit' }
						},
						{
							path: 'cancel', name: 'DocsProjectsCancel', component: DocsProjectsCancel, meta: { title: 'Cancel' }
						},
						{
							path: ':id', name: 'DocsProjectsGet', component: DocsProjectsGet, meta: { title: 'Options' }
						}
					]
				},
				{
					path: '/docs/builder', name: 'DocsBuilder', component: DocsBuilder, meta: { title: 'Builder' },
					children: [
						{
							path: 'create-project', name: 'DocsBuilderCreateProject', component: DocsBuilderCreateProject, meta: { title: 'Create Project' }
						},
						{
							path: 'create-scenario', name: 'DocsBuilderCreateScenario', component: DocsBuilderCreateScenario, meta: { title: 'Create Scenario' }
						},
						{
							path: 'zip-project', name: 'DocsBuilderZipProject', component: DocsBuilderZipProject, meta: { title: 'Zip Project' }
						}
					]
				},
				{
					path: '/docs/query', name: 'DocsQuery', component: DocsQuery, meta: { title: 'Query' },
					children: [
						{
							path: 'datasets', name: 'DocsQueryDatasets', component: DocsQueryDatasets, meta: { title: 'Datasets' }
						},
						{
							path: 'subbasins', name: 'DocsQuerySubbasins', component: DocsQuerySubbasins, meta: { title: 'Subbasins' }
						}
					]
				}
			]
		},
		{
			path: '/admin', name: 'AdminDashboard', component: AdminDashboard, meta: { title: 'Admin Dashboard' },
			children: [
				{
					path: 'clients', name: 'AdminClients', component: AdminClients, meta: { title: 'API Clients' },
					children: [
						{
							path: ':id', name: 'AdminClientsView', component: AdminClientsView, meta: { title: 'Details' }
						}
					]
				},
				{
					path: 'requests', name: 'AdminRequests', component: AdminRequests, meta: { title: 'API Requests' },
					children: [
						{
							path: ':id', name: 'AdminRequestsView', component: AdminRequestsView, meta: { title: 'Details' }
						}
					]
				},
				{
					path: 'users', name: 'AdminUsers', component: AdminUsers, meta: { title: 'API Administrators' }
				}
			]
		},
		{
			path: '*',
			redirect: '/'
		}
	]
})

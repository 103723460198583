<template>
	<page-authorization-container :page="page" no-auth>
		<h2 class="mb-3">Create a Project</h2>

		<b-card no-body class="mb-3 border">
			<b-tabs card>
				<b-tab title="Input Builder">
					<p>
						Use the form below to build a sample input JSON object.
						Note if a parameter is not required, it may be omitted from your object definition.
						<br />Use the input definitions API to get all parameter options, descriptions, and units.
					</p>

					<error-list :errors="page.example.errors"></error-list>

					<div class="d-flex align-items-center mb-3">
						<div class="mr-1">
							<api-linker :options-url="inputs.url"></api-linker>
						</div>
						<div class="ml-auto mr-3">
							<b-form-checkbox v-model="show.onlyBasic" switch v-b-tooltip.hover="'Some of the inputs for submitting a project are optional and uncommon for most users. Beginners may use this switch to hide/show some of these advanced input parameters.'">
								Show only basic parameters
							</b-form-checkbox>
						</div>
						<div>
							<save-button variant="primary" @click.native="loadExample" @saving="page.example.loading" type="button"
										 text="Load Example Project"
										 v-b-tooltip.hover="'Fill input parameters with an example watershed using minimal, basic inputs only.'"></save-button>
						</div>
					</div>

					<b-table-simple class="border-bottom table-valign-m table-form" stacked="lg" small>
						<b-thead class="bg-light">
							<b-tr>
								<b-th class="min">Parameter Name</b-th>
								<b-th>Input Builder</b-th>
								<b-th>Description</b-th>
								<b-th class="min">Value Type</b-th>
								<b-th class="min text-center">Required</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr>
								<b-td><label for="dataset"><code>dataset</code></label></b-td>
								<b-td>
									<b-form-select id="dataset" v-model="query.dataset" :options="getInput('dataset').options" required>
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
									</b-form-select>
								</b-td>
								<b-td>{{getInput('dataset').description}}</b-td>
								<b-td>{{getInput('dataset').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('dataset').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr>
								<b-td><label for="downstreamSubbasin"><code>downstreamSubbasin</code></label></b-td>
								<b-td>
									<b-typeahead ref="downstreamSubbasinTypeahead" placeholder="Type to search..." :minMatchingChars="2"
												 :data="dynamicOptions.downstreamSubbasins == null ? [] : dynamicOptions.downstreamSubbasins"
												 v-model="downstreamSubbasinSearch"
												 :serializer="s => s.name"
												 :max-matches="100">
									</b-typeahead>
								</b-td>
								<b-td>{{getInput('downstreamSubbasin').description}}</b-td>
								<b-td>{{getInput('downstreamSubbasin').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('downstreamSubbasin').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="!show.onlyBasic">
								<b-td><label for="upstreamSubbasins"><code>upstreamSubbasins</code></label></b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="2"
												 :data="dynamicOptions.upstreamSubbasins == null ? [] : dynamicOptions.upstreamSubbasins"
												 v-model="upstreamSubbasinsSearch"
												 :serializer="s => s.name"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addUpstreamSubbasin" variant="secondary" v-b-tooltip.hover="'Add upstream subbasin'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(s, i) in query.upstreamSubbasins" :key="i" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'" @click="removeUpstreamSubbasin(s)">
											{{s}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('upstreamSubbasins').description}}</b-td>
								<b-td>{{getInput('upstreamSubbasins').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('upstreamSubbasins').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="!show.onlyBasic">
								<b-td><label for="upstreamDataSource"><code>upstreamDataSource</code></label></b-td>
								<b-td>
									<b-form-select id="upstreamDataSource" v-model="query.upstreamDataSource" @change="setSimulationDates">
										<template #first>
											<b-form-select-option :value="null">-- Select a value --</b-form-select-option>
										</template>
										<b-form-select-option v-for="(o, k) in upstreamDataSourceOptions" :key="k" :value="o.value">{{o.value}}</b-form-select-option>
									</b-form-select>
									<div v-if="selectedUpstreamDataSource !== null" class="mt-1 text-muted ml-1">
										<small>{{selectedUpstreamDataSource.startDate | date('MM/DD/YYYY')}} - {{selectedUpstreamDataSource.endDate | date('MM/DD/YYYY')}}</small>
									</div>
								</b-td>
								<b-td>{{getInput('upstreamDataSource').description}}</b-td>
								<b-td>{{getInput('upstreamDataSource').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('upstreamDataSource').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr class="bg-light pointer" @click="show.setHrus = !show.setHrus" title="Click to toggle parameters">
								<b-td>
									<font-awesome-icon :icon="show.setHrus ? ['fas', 'minus'] : ['fas', 'plus']" class="text-muted mr-2" />
									<code>setHrus</code>
								</b-td>
								<b-td></b-td>
								<b-td>{{getInput('setHrus').description}}</b-td>
								<b-td>{{getInput('setHrus').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('setHrus').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.setHrus">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><label for="setHrusMethod"><code>method</code></label></b-td>
								<b-td>
									<b-form-select id="setHrusMethod" v-model="query.setHrus.method" :options="getInput('method', shortcuts.setHrus).options" required>
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
									</b-form-select>
								</b-td>
								<b-td>{{getInput('method', shortcuts.setHrus).description}}</b-td>
								<b-td>{{getInput('method', shortcuts.setHrus).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('method', shortcuts.setHrus).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.setHrus">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><label for="setHrusTarget"><code>target</code></label></b-td>
								<b-td>
									<b-form-input id="setHrusTarget" v-model="query.setHrus.target" type="number" step="any" required />
								</b-td>
								<b-td>{{getInput('target', shortcuts.setHrus).description}}</b-td>
								<b-td>{{getInput('target', shortcuts.setHrus).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('target', shortcuts.setHrus).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.setHrus">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><label for="setHrusUnits"><code>units</code></label></b-td>
								<b-td>
									<b-form-select id="setHrusUnits" v-model="query.setHrus.units" :options="getInput('units', shortcuts.setHrus).options" required>
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
									</b-form-select>
								</b-td>
								<b-td>{{getInput('units', shortcuts.setHrus).description}}</b-td>
								<b-td>{{getInput('units', shortcuts.setHrus).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('units', shortcuts.setHrus).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.setHrus && !show.onlyBasic">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><label for="setHrusExemptLanduse"><code>exemptLanduse</code></label></b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="1" showAllResults showOnFocus
												 :data="dynamicOptions.exemptLanduse == null ? [] : dynamicOptions.exemptLanduse"
												 v-model="exemptLanduseSearch"
												 :serializer="s => s.code"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addExemptLanduse" variant="secondary" v-b-tooltip.hover="'Add landuse'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<span v-html="htmlText"></span>  - {{data.description}}
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(elu, j) in query.setHrus.exemptLanduse" :key="j" @click="removeExemptLanduse(elu)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{elu}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('exemptLanduse', shortcuts.setHrus).description}}</b-td>
								<b-td>{{getInput('exemptLanduse', shortcuts.setHrus).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('exemptLanduse', shortcuts.setHrus).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.setHrus && !show.onlyBasic" class="thick-border">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><label for="setHrusNoAreaRedistribution"><code>noAreaRedistribution</code></label></b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="1" showAllResults showOnFocus
												 :data="dynamicOptions.noAreaRedistribution == null ? [] : dynamicOptions.noAreaRedistribution"
												 v-model="noAreaRedistributionSearch"
												 :serializer="s => s.code"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addNoAreaRedistribution" variant="secondary" v-b-tooltip.hover="'Add landuse'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<span v-html="htmlText"></span>  - {{data.description}}
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(elu, j) in query.setHrus.noAreaRedistribution" :key="j" @click="removeNoAreaRedistribution(elu)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{elu}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('noAreaRedistribution', shortcuts.setHrus).description}}</b-td>
								<b-td>{{getInput('noAreaRedistribution', shortcuts.setHrus).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('noAreaRedistribution', shortcuts.setHrus).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="!show.onlyBasic">
								<b-td><label for="useInWebInterface"><code>useInWebInterface</code></label></b-td>
								<b-td>
									<b-form-checkbox id="useInWebInterface" v-model="query.useInWebInterface"></b-form-checkbox>
								</b-td>
								<b-td>{{getInput('useInWebInterface').description}}</b-td>
								<b-td>{{getInput('useInWebInterface').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('useInWebInterface').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="!show.onlyBasic">
								<b-td><label for="projectName"><code>projectName</code></label></b-td>
								<b-td>
									<b-form-input id="projectName" v-model="query.projectName" type="text" />
								</b-td>
								<b-td>{{getInput('projectName').description}}</b-td>
								<b-td>{{getInput('projectName').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('projectName').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-tab>
				<b-tab title="Input JSON">
					<p>
						The submit project API uses the HTTP POST method. The object should be submitted in JSON format.
						The object below shows the JSON generated from the data provided in the Input Builder tab.
					</p>
					<highlightjs language="javascript" :code="queryCode" />
				</b-tab>
				<b-tab title="Output">
					<p>
						Projects will take time to create in the background, and output downloads will not be available immediately.
						After submitting your project via HTTP POST, you will receive a URL in the response, which you may then use in an HTTP GET request
						to query the project status and results.
					</p>

					<h3 class="h5 mt-4 mb-3">Response from HTTP POST</h3>
					<b-table-simple class="border-bottom table-valign-m table-form" small>
						<b-thead class="bg-light">
							<b-tr>
								<b-th class="min" style="width:18%">Parameter Name</b-th>
								<b-th>Description</b-th>
								<b-th class="min">Value Type</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr>
								<b-td><code>id</code></b-td>
								<b-td>Unique, auto-assigned identifying number of your request</b-td>
								<b-td>int</b-td>
							</b-tr>
							<b-tr>
								<b-td><code>url</code></b-td>
								<b-td>Full URL to query project status and results via HTTP GET method</b-td>
								<b-td>string</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<h3 class="h5 mt-4 mb-3">Response from HTTP GET using URL from submission response</h3>
					<b-table-simple class="border-bottom table-valign-m table-form" small>
						<b-thead class="bg-light">
							<b-tr>
								<b-th class="min" style="width:18%">Parameter Name</b-th>
								<b-th>Description</b-th>
								<b-th class="min">Value Type</b-th>
							</b-tr>
						</b-thead>
						<b-tbody v-for="d in outputs.definitions" :key="d.name">
							<b-tr>
								<b-td class="min"><code>{{d.name}}</code></b-td>
								<b-td>{{d.description}}</b-td>
								<b-td>{{d.type}}</b-td>
							</b-tr>
							<b-tr v-for="c in d.childParameters" :key="c.name" class="bg-light">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><code>{{c.name}}</code></b-td>
								<b-td>{{c.description}}</b-td>
								<b-td>{{c.type}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<api-linker :options-url="outputs.url" options-type="Output"></api-linker>
				</b-tab>
				<b-tab title="API Submission">
					<b-alert variant="info" class="mb-4" show>
						<strong>Code samples are available on <a href="https://github.com/blackland-tamu-edu/hawqs-api-examples" target="_blank">GitHub</a>.</strong>
					</b-alert>

					<p>
						To create a project, send a POST to the following URL with the JSON object in the body.
					</p>

					<api-linker label="HTTP POST" url="builder/project/create-only" no-run-button></api-linker>

					<p class="mt-3">
						Get the project request ID from the response of the above method. Use the ID to query the project status and results.
					</p>

					<api-linker label="HTTP GET" url="builder/project/[:id]" no-run-button></api-linker>
				</b-tab>
			</b-tabs>
		</b-card>
	</page-authorization-container>
</template>

<script>
	import _ from 'underscore';
	import moment from 'moment';
	import shajs from 'sha.js';

	export default {
		name: 'DocsBuilderCreateProject',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					example: {
						errors: [],
						loading: false
					}
				},
				inputs: {
					url: 'builder/project/input-definitions',
					definitions: []
				},
				outputs: {
					url: 'builder/project/output-definitions',
					definitions: []
				},
				query: {
					dataset: null,
					downstreamSubbasin: null,
					upstreamSubbasins: [],
					upstreamDataSource: null,
					setHrus: {
						method: null,
						target: null,
						units: null,
						exemptLanduse: [],
						noAreaRedistribution: []
					},
					useInWebInterface: false,
					projectName: null
				},
				shortcuts: {
					setHrus: []
				},
				show: {
					setHrus: true,
					onlyBasic: true
				},
				dynamicOptions: {
					downstreamSubbasins: [],
					upstreamSubbasins: [],
					exemptLanduse: [],
					noAreaRedistribution: []
				},
				downstreamSubbasinSearch: null,
				upstreamSubbasinsSearch: null,
				exemptLanduseSearch: null,
				noAreaRedistributionSearch: null,
				apiKeys: {
					shared: null,
					secret: null
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get',
			downstreamSubbasinSearch: _.debounce(function (query) { this.findDownstreamSubbasins(query) }, 500),
			upstreamSubbasinsSearch: _.debounce(function (query) { this.findUpstreamSubbasins(query) }, 500),
			exemptLanduseSearch: _.debounce(function (query) { this.findExemptLanduse(query) }, 500),
			noAreaRedistributionSearch: _.debounce(function (query) { this.findNoAreaRedistribution(query) }, 500)
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				await this.loadApiKeys();
				try {
					const response = await this.$http.get(this.inputs.url);
					this.log(response.data);
					this.inputs.definitions = response.data;

					const response2 = await this.$http.get(this.outputs.url);
					this.log(response2.data);
					this.outputs.definitions = response2.data;

					this.shortcuts.setHrus = this.getInput('setHrus').childParameters;

					this.query.dataset = this.getInput('dataset').defaultValue;
					this.query.setHrus.method = this.getInput('method', this.shortcuts.setHrus).defaultValue;
					this.query.setHrus.target = this.getInput('target', this.shortcuts.setHrus).defaultValue;
					this.query.setHrus.units = this.getInput('units', this.shortcuts.setHrus).defaultValue;
					this.query.setHrus.exemptLanduse = this.getInput('exemptLanduse', this.shortcuts.setHrus).defaultValue;
					this.query.setHrus.noAreaRedistribution = this.getInput('noAreaRedistribution', this.shortcuts.setHrus).defaultValue;

					this.findExemptLanduse('');
					this.findNoAreaRedistribution('');
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async loadApiKeys() {
				if (this.isAuthenticated) {
					try {
						const response = await this.$http.get(`admin/apiclients/find/${this.user.userName}`, this.getTokenHeader());
						this.apiKeys.shared = response.data.sharedKey;
						this.apiKeys.secret = response.data.secretKey;
					} catch (error) {
						this.logError(error);
					}
				}
			},
			async loadExample() {
				this.page.example.errors = [];
				this.page.example.loading = true;

				try {
					const response = await this.$http.get('builder/project/example');
					this.log(response.data);

					this.show.onlyBasic = true;

					this.downstreamSubbasinSearch = response.data.downstreamSubbasin;
					try { this.$refs.downstreamSubbasinTypeahead.$data.inputValue = response.data.downstreamSubbasin; } catch (e) { this.log(e); }

					this.query.dataset = response.data.dataset;
					this.query.downstreamSubbasin = response.data.downstreamSubbasin;
					this.query.setHrus.method = response.data.setHrus.method;
					this.query.setHrus.target = response.data.setHrus.target;
					this.query.setHrus.units = response.data.setHrus.units;
				} catch (error) {
					this.page.example.errors = this.logError(error);
				}

				this.page.example.loading = false;
			},
			getInput(param, list = null) {
				if (list === null) list = this.inputs.definitions;
				let obj = this.findInList(list, 'name', param);
				if (obj === null) return {};
				return obj;
			},
			async findDownstreamSubbasins(query) {
				try {
					const response = await this.$http.get(this.getSubbasinSearchUrl(query));
					this.dynamicOptions.downstreamSubbasins = response.data != '' && response.data != null ? response.data : [];
					if (!this.isNullOrEmpty(this.downstreamSubbasinSearch)) this.query.downstreamSubbasin = this.downstreamSubbasinSearch;
				} catch (error) {
					this.logError(error);
				}
			},
			async findUpstreamSubbasins(query) {
				try {
					const response = await this.$http.get(this.getSubbasinSearchUrl(query));
					this.dynamicOptions.upstreamSubbasins = response.data != '' && response.data != null ? response.data : [];
				} catch (error) {
					this.logError(error);
				}
			},
			getSubbasinSearchUrl(query) {
				return `query/subbasins?dataset=${this.query.dataset}&startsWith=${query}`;
			},
			addUpstreamSubbasin() {
				if (!this.isNullOrEmpty(this.upstreamSubbasinsSearch)) this.query.upstreamSubbasins.push(this.upstreamSubbasinsSearch);
			},
			removeUpstreamSubbasin(s) {
				this.query.upstreamSubbasins = this.query.upstreamSubbasins.filter(function (el) { return el !== s; });
			},
			async findExemptLanduse(query) {
				try {
					const response = await this.$http.get(this.getLanduseSearchUrl(query));
					this.dynamicOptions.exemptLanduse = response.data != '' && response.data != null ? response.data : [];
				} catch (error) {
					this.logError(error);
				}
			},
			async findNoAreaRedistribution(query) {
				try {
					const response = await this.$http.get(this.getLanduseSearchUrl(query));
					this.dynamicOptions.noAreaRedistribution = response.data != '' && response.data != null ? response.data : [];
				} catch (error) {
					this.logError(error);
				}
			},
			getLanduseSearchUrl(query) {
				return `query/landuse?dataset=${this.query.dataset}&startsWith=${query}`;
			},
			addExemptLanduse() {
				if (!this.isNullOrEmpty(this.exemptLanduseSearch)) this.query.setHrus.exemptLanduse.push(this.exemptLanduseSearch);
			},
			removeExemptLanduse(s) {
				this.query.setHrus.exemptLanduse = this.query.setHrus.exemptLanduse.filter(function (el) { return el !== s; });
			},
			addNoAreaRedistribution() {
				if (!this.isNullOrEmpty(this.noAreaRedistributionSearch)) this.query.setHrus.noAreaRedistribution.push(this.noAreaRedistributionSearch);
			},
			removeNoAreaRedistribution(s) {
				this.query.setHrus.noAreaRedistribution = this.query.setHrus.noAreaRedistribution.filter(function (el) { return el !== s; });
			},
			addToArray(value, array) {
				if (!this.isNullOrEmpty(value)) array.push(value);
			},
			removeFromArray(value, array) {
				return array.filter(function (el) { return el !== value; });
			}
		},
		computed: {
			queryCode() {
				return JSON.stringify(this.queryObject, null, 4);
			},
			queryObject() {
				if (!this.show.onlyBasic) return this.query;

				let obj = {
					dataset: this.query.dataset,
					downstreamSubbasin: this.query.downstreamSubbasin,
					setHrus: {
						method: this.query.setHrus.method,
						target: this.query.setHrus.target,
						units: this.query.setHrus.units
					}
				};

				return obj;
			},
			upstreamDataSourceOptions() {
				let byDataset = this.getInput('upstreamDataSource').options;
				if (this.isNullOrEmpty(byDataset)) return [];
				let obj = this.findInList(byDataset, 'dataset', this.query.dataset);
				if (obj === null) return [];
				return obj.upstreamDataSources;
			},
			selectedUpstreamDataSource() {
				return this.findInList(this.upstreamDataSourceOptions, 'value', this.query.upstreamDataSource);
			}
		}
	}
</script>

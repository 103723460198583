<template>
	<page-authorization-container :page="page" no-auth>
		<h2 class="mb-3">Zip Project</h2>

		<p>
			When you are done creating and running scenarios on your project, you may submit an API request to zip the project.
			If any of your scenarios set the <code>writeSwatEditorDb</code> input parameter to <code>access</code>, then GIS data will be included in the zip file.
		</p>

		<h3 class="mt-5 mb-3">API Submission</h3>

		<b-alert variant="info" class="mb-4" show>
			<strong>Code samples are available on <a href="https://github.com/blackland-tamu-edu/hawqs-api-examples" target="_blank">GitHub</a>.</strong>
		</b-alert>

		<p>
			Zip the project with an HTTP PATCH request with no content in the body.
		</p>

		<api-linker label="HTTP PATCH" url="builder/project/zip/[:id]" no-run-button class="mb-3"></api-linker>

		<p>
			Query the project URL to retrieve the zip file status and files.
		</p>

		<api-linker label="HTTP GET" url="builder/project/[:id]" no-run-button></api-linker>
	</page-authorization-container>
</template>

<script>
	export default {
		name: 'DocsBuilderZipProject',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				apiKeys: {
					shared: null,
					secret: null
				}
			}
		},
		async created() {
			await this.loadApiKeys();
		},
		methods: {
			async loadApiKeys() {
				if (this.isAuthenticated) {
					this.page.loading = true;
					try {
						const response = await this.$http.get(`admin/apiclients/find/${this.user.userName}`, this.getTokenHeader());
						this.apiKeys.shared = response.data.sharedKey;
						this.apiKeys.secret = response.data.secretKey;
					} catch (error) {
						this.logError(error);
					}
					this.page.loading = false;
				}
			}
		}
	}
</script>

<template>
	<div>
		<div v-if="$route.name == 'DocsBuilder'">

		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'DocsBuilder',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				}
			}
		}
	}
</script>
